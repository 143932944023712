.App {
  background-color: #fefefe;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; 
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f3f4f6; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #3b82f6; 
  border-radius: 6px; 
}


.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #2563eb; 
}


